.icon-card-wrapper {
  display: flex;
  align-items: center;
  background-color: rgba(233, 150, 122, 0.4);
}

#icon {
  padding: 10px;
}

.icon-card-desc {
  padding: 10px 0;
}