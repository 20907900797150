#banner {
  /* background-image: url(/public/images/world-map.png); */
  /* background-repeat: no-repeat; */
  /* background-color: "#104E21"; */
  width: "100%";
  /* border: 1px red solid; */
}

#title {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* width: 200px; */
  /* height: 200px; */
  /* border: 2px white solid; */
  /* border-radius: 50%; */
  /* text-align: center; */
  /* margin-left: 375px; */
  /* margin-bottom: 100px; */
  /* color: #ffffff; */
  /* background: #104E21; */
  opacity: 0.9;
  /* border: 1px red solid; */
}

@media only screen and (min-width: 600px) {
  #banner {
    justify-content: center;
  }

  #circle {
    margin: 0 auto;
    margin-left: 64px;
    /* border-radius: 10%; */
  }
}

@media only screen and (max-width: 600px) {
  #banner {
    justify-content: center;
  }

  #circle {
    margin: 0 auto;
  }
}

