.carousel {
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
  height: 480px;
  /* background-image: url(/public/images/carousel-bg.jpg); */
  background-image: url(/public/images/world-map.png);
  background-color: #104E21;
}

.carousel-item {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  height: fit-content;
  border: 1px solid red;
}

#navigation {
  position: absolute;
  width: 100%;
  height: calc(50%);
  z-index: 1;
  display: flex;
  justify-content: space-between;
}

#navigation .arrows {
  width: 100px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 45%;
  opacity: .5;
  cursor: pointer;
}

#navigation > #left  {
  background-image: url(/public/images/arrow-left.svg);
}

#navigation > #right  {
  background-image: url(/public/images/arrow-right.svg);
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.indicators {
  position: relative;
  top: -50px;
  display: flex;
  justify-content: center;
  z-index: 11;
  /* border: 1px red solid; */
}

.indicators > button {
  margin: 5px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

.indicators > button.active {
  background-color: #104E21;
  color: #ffffff;
  border: none;
}