.header {
  width: 100%;
  height: 60px;
  line-height: 20px;
  /* border: 1px solid white; */
  z-index: 10;
  position: fixed;
  top: -1px;
  left: 0;
}
.sticky {
  /* width: 100%; */
  transition: box-shadow 250ms ease-in-out;
  box-shadow: 0 10px 15px -3px rgba(19, 217, 161, 0.1);
}
.none {
  display: none;
}
#wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}